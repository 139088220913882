
import { Options, Vue } from "vue-class-component";
import Table from "@/components/Table.vue";
import MirroringUserBanner from "@/components/tuition-fees/MirroringUserBanner.vue";
import { SessionStorageKeys } from "@/constants/constants";
import DateHelper from "@/helpers/date-helper";
import IdHelper from "@/helpers/id-helper";
import DownloadHelper from "@/helpers/tuitionfees/download-helper";
import UnauthorizedHelper from "@/helpers/tuitionfees/unauthorized-helper";
import { Invoice, TfPerson } from "@/interfaces/api";
import InvoiceService from "@/services/tuition-fees/invoice-service";
import SessionStorageService from "@/services/sessionstorage-service";

@Options({
  components: { MirroringUserBanner, Table },
  data: () => ({
    /**
     * The current user object
     */
    currentUser: {},
    /**
     * The unpaid invoices
     */
    unpaidInvoices: [],
    /**
     * The paid invoices
     */
    paidInvoices: [],
    /**
     * The personal id of the user being mirrored by admin
     */
    personalId: "",
    /**
     * The name of the user being mirrored by admin
     */
    personName: "",
    /**
     * The lucat id of the user being mirrored by admin
     */
    lucatId: "",
    /**
     * A boolean to indicate if tfp-admin is mirroring user or not
     * to access TuitionFeeHome
     */
    isMirroringUser: false,
  }),
  computed: {},
  methods: {
    getInvoices() {
      this.$store.commit("showSpinner", true);

      const mirroredUser: TfPerson | undefined = SessionStorageService.getItem(
        SessionStorageKeys.MirroredUser
      );

      if (mirroredUser) {
        this.personalId = mirroredUser.personalId;
        this.personName = mirroredUser.displayName;
        this.lucatId = mirroredUser.luEduPersonPrimaryId;
      }

      const personalId = IdHelper.getPersonalId(this.personalId);

      InvoiceService.getInvoices(personalId)
        .then((response) => {
          const unpaidInvoices = response.filter(
            (invoice: Invoice) => !invoice.payFlag || invoice.payFlag === "0"
          );

          this.unpaidInvoices = this.mapInvoices(unpaidInvoices);

          const paidInvoices = response.filter(
            (invoice: Invoice) => invoice.payFlag === "1"
          );

          this.paidInvoices = this.mapInvoices(paidInvoices);
          this.paidInvoices.sort((a: Invoice, b: Invoice) => {
            return parseInt(b.fakNr) - parseInt(a.fakNr);
          });
        })
        .catch((e: Error) => {
          console.error(e);
        })
        .finally(() => {
          this.$store.commit("showSpinner", false);
        });
    },
    /*
     * Formats the date to YYYY-MM-DD
     */
    formatDate(date: Date): string {
      return DateHelper.formatDate(date);
    },
    /*
     * Checks if the invoice is running late or partially paid and returns the appropriate class
     */
    getDateClass(
      dueDate: Date,
      amountDueString: string,
      totalAmountString: string,
      pending: boolean
    ): string {
      if (!pending && this.paymentIsRunningLate(dueDate)) {
        return "date-is-late";
      } else if (
        this.invoiceIsNotFullyPaid(amountDueString, totalAmountString)
      ) {
        return "partial-payment-due";
      }
      return "";
    },
    /*
     * Checks if the dueDate is within one week from now (or if it has passed)
     */
    paymentIsRunningLate(dueDate: Date): boolean {
      const now = new Date();
      if (
        DateHelper.dateAisBeforeDateB(dueDate, now) ||
        DateHelper.dateAisBeforeDateB(dueDate, DateHelper.addDaysToDate(now, 7))
      ) {
        return true;
      }
      return false;
    },
    /*
     * Checks if the invoice is fully paid
     */
    invoiceIsNotFullyPaid(
      amountDueString: string,
      totalAmountString: string
    ): boolean {
      const amountDue = this.convertToInt(amountDueString);
      const totalAmount = this.convertToInt(totalAmountString);

      if (amountDue > 0 && amountDue < totalAmount) {
        return true;
      }

      return false;
    },
    /**
     * Maps an array of full Invoice objects to an array of partial Invoice objects
     */
    mapInvoices(invoices: Array<Invoice>): Array<Partial<Invoice>> {
      return invoices.map((invoice: Invoice) => {
        return {
          fakNr: invoice.fakNr,
          totalAmount: invoice.totalAmount,
          amountDue: invoice.amountDue,
          dueDate: invoice.dueDate,
          description: invoice.description,
          pending: invoice.pending,
          links: null,
        };
      });
    },
    /**
     * Converts the specified string to an integer
     */
    convertToInt(value: string): number {
      return parseInt(value.replaceAll(".", "").replaceAll(",", ""));
    },
    getCurrentEntitlements() {
      this.currentUser = this.$store.getters.getCurrentUser;
    },
    /**
     * If not logged in or not a student, redirect to unauthorized page
     * Otherwise, check if admin is mirroring a user, if so show banner
     * if not redirect to unauthorized page
     */
    checkIfUserIsStudentOrAdminMirroringUser(): void {
      console.log("~ Current User:", this.currentUser);
      this.isMirroringUser =
        UnauthorizedHelper.checkIfUserIsStudentOrAdminMirroringUser(
          this.currentUser
        );
    },
    /**
     * Downloads the specified invoice as a PDF file
     */
    async downloadInvoice(invoiceNo: string) {
      await DownloadHelper.downloadInvoice(invoiceNo);
    },
  },
  mounted() {
    this.getInvoices();
    this.getCurrentEntitlements();
    this.checkIfUserIsStudentOrAdminMirroringUser();
  },
})
export default class TuitionFeeHome extends Vue {}
